<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="per_pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-1"
              variant="primary"
              @click="addVideoModal = true"
            >
              Add Video
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Add Video Modal -->
      <b-modal
        v-model="addVideoModal"
        hide-footer
        centered
        size="lg"
        title="Add Video"
      >
        <b-form-group label="Enter the Video's Title" label-for="enterTitle">
          <b-form-input
            id="enterTitle"
            placeholder="Enter the Video's Title"
            v-model="addVideoTitle"
          />
        </b-form-group>
        <b-form-group
          label="Select Video's Categories"
          label-for="selectCategories"
        >
          <div class="demo-inline-spacing">
            <b-form-checkbox
              v-for="category in categories"
              :key="category.value"
              v-model="addVideoCategories"
              :value="category.value"
            >
              {{ category.text }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group label="Video Description" label-for="videoDescription">
          <b-form-textarea
            v-model="addVideoDescription"
            placeholder="Enter the video's description"
            rows="3"
            :state="addVideoDescription.length <= maxChar"
            class="char-textarea"
            :class="addVideoDescription.length >= maxChar ? 'text-danger' : ''"
          />
          <small
            class="textarea-counter-value float-right"
            :class="addVideoDescription.length >= maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ addVideoDescription.length }}</span> /
            {{ maxChar }}
          </small>
        </b-form-group>

        <b-form-group label="Select Video's File" label-for="selectFile">
          <b-form-file
            v-model="addVideoFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".mp4"
          />

          <b-card-text class="my-1">
            Selected file: <strong>{{ addVideoFile ? addVideoFile.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <b-button variant="outline-primary" @click="storeVideo()">
          Submit
        </b-button>
      </b-modal>

      <b-table
        ref="refVideosListTable"
        class="position-relative"
        :items="fetchVideos"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Author -->
        <template #cell(categories)="data">
          <div class="text-wrap">
            <b-badge
              v-for="category in data.item.categories"
              :key="category.id"
              pill
              :variant="`light-success`"
              class="text-capitalize"
            >
              {{ category.name }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Uploaded on -->
        <template #cell(uploaded-on)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              moment(data.item.created_at).format("dddd, MMMM Do YYYY")
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data"
          ><b-button
            variant="outline-info"
            :to="{ name: 'apps-video-view', params: { id: data.item.id } }"
          >
            Details
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalVideos"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect,
  BModal,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useVideoList from "./useVideosList";
import videoStore from "./videoStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormSelect,
    BModal,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BCardText,

    vSelect,
  },
  setup() {
    const VIDEO_APP_STORE_MODULE_NAME = "app-videos";

    const maxChar = ref(200);

    const categories = [];

    const fetchCategories = () => {
      store
        .dispatch("app-categories/fetchAllCategories")
        .then((response) => {
          response.data.forEach((category) => {
            categories.push({ value: category.id, text: category.name });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchCategories();

    // Register module
    if (!store.hasModule(VIDEO_APP_STORE_MODULE_NAME))
      store.registerModule(VIDEO_APP_STORE_MODULE_NAME, videoStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VIDEO_APP_STORE_MODULE_NAME))
        store.unregisterModule(VIDEO_APP_STORE_MODULE_NAME);
    });

    const isAddNewVideoSidebarActive = ref(false);

    const {
      fetchVideos,
      storeVideo,
      tableColumns,
      per_page,
      current_page,
      totalVideos,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refVideosListTable,
      refetchData,

      addVideoModal,
      addVideoTitle,
      addVideoCategories,
      addVideoDescription,
      addVideoFile,
    } = useVideoList();

    return {
      // Sidebar
      isAddNewVideoSidebarActive,

      fetchVideos,
      storeVideo,
      tableColumns,
      per_page,
      current_page,
      totalVideos,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refVideosListTable,
      refetchData,

      categories,
      maxChar,

      // Add Article
      addVideoModal,
      addVideoTitle,
      addVideoCategories,
      addVideoDescription,
      addVideoFile,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
